var bowser = require('bowser');

var animationTime = 1250;

var ageWrapper = document.getElementById('age');
var body = document.getElementsByTagName('body')[0];
var html = document.getElementsByTagName('html')[0];

var animations = Array.prototype.slice.call(document.querySelectorAll('.animation'));
var sections = Array.prototype.slice.call(document.querySelectorAll('section'));

var animationStartTime;
var currentGradient = fillGradient(255);
var currentSection;
var gradientAnimation;
var gradientFrom;
var gradientTo;
var previousGradientString;
var previousScrollPosition = 0;

var gradientClass = 'gradient-background'

if (bowser.ios || (bowser.mac && Modernizr.touchevents)) {
    var newDiv = document.createElement("div");
    newDiv.classList.add(gradientClass, 'ios');

    body.appendChild(newDiv);
} else {
    html.classList.add(gradientClass);
}

setCompanyAge();

const throttledScrollHandler = throttle(scrollHandler, 100);
window.onload = scrollHandler;
window.onscroll = throttledScrollHandler;

function fillGradient(v) {
    return [
        [v, v, v],
        [v, v, v],
        [v, v, v],
        [v, v, v],
        [v, v, v]
    ];
}

function isInView(element, isForwardScrolling) {
    var isInView = false;

    var windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

    var viewportDivisor = 2;
    if (element.hasAttribute('data-scroll-viewport-divisor')) {
        viewportDivisor =
            Number(element.getAttribute('data-scroll-viewport-divisor'));
    }

    var offset = (windowHeight / viewportDivisor);

    var rect = element.getBoundingClientRect();
    if (isForwardScrolling) {
        isInView =
            rect.top + windowHeight - offset <= windowHeight
            && (rect.top + rect.height) >= 0;
    }
    else {
        isInView =
            rect.bottom >= windowHeight - offset
            && rect.bottom >= 0;
    }

    return isInView;
}

function scrollHandler() {
    var currentScrollPosition =
        window.scrollY || window.scrollTop || html.scrollTop;

    var isForwardScrolling = previousScrollPosition <= currentScrollPosition;

    var visibleSections = [];

    sections.forEach(function (section) {
        if (isInView(section, isForwardScrolling)) {
            visibleSections.push(section);
        }
    });

    if (visibleSections.length > 0) {
        var upcomingSection = visibleSections[0];

        if (isForwardScrolling
            && visibleSections.length > 1) {
            upcomingSection = visibleSections[visibleSections.length - 1];
        }

        if (currentSection !== upcomingSection) {
            currentSection = upcomingSection;
            setBackground(currentSection);
        }
    }

    animations.forEach((animation) => {
        if (isInView(animation, isForwardScrolling)
            && !animation.classList.contains('animate')) {
            animation.classList.add('animate');
        }
    });

    previousScrollPosition = currentScrollPosition;
};

function setBackground(section) {
    gradientFrom = currentGradient.map(arr => arr.slice());
    gradientTo = fillGradient(255);

    animationStartTime = performance.now();

    if (section.hasAttribute('data-gradient')) {
        var colors =
            section
                .getAttribute('data-gradient')
                .replace(/[()]/g, '')
                .split(' ');

        gradientTo = colors.map(color =>
            color
                .split(',')
                .map(n => parseInt(n, 10))
        );
    }

    startGradientAnimation();
}

function setCompanyAge() {
    var locale = document.documentElement.lang;
    var birthday = new Date(2004, 2, 27);

    var ageInYears =
        (new Date(new Date() - new Date(birthday)))
            .getFullYear() - 1970;

    if (ageInYears < 15) { // invalid client clock
        ageInYears = 15;
    }

    var title = locale === "en" ? 'years' : 'лет';

    // good enough till 2035
    if (ageInYears > 20
        && ageInYears < 25
        && locale === 'ru') {
        if (ageInYears === 21) {
            title = 'год';
        } else {
            title = 'года';
        }
    }

    ageWrapper.innerHTML = ageInYears + ' ' + title;
}

function startGradientAnimation() {
    if (!gradientAnimation) {
        gradientAnimation = window.requestAnimationFrame(updateGradient);
    }
}

function stopGradientAnimation() {
    if (gradientAnimation) {
        gradientAnimation = undefined;
    }
}

function throttle(callback, delay) {
    let isThrottled = false, args, context;

    function wrapper() {
        if (isThrottled) {
            args = arguments;
            context = this;
            return;
        }

        isThrottled = true;
        callback.apply(this, arguments);

        setTimeout(() => {
            isThrottled = false;
            if (args) {
                wrapper.apply(context, args);
                args = context = null;
            }
        }, delay);
    }

    return wrapper;
}

function updateGradient() {
    var now = performance.now();

    var progress = (now - animationStartTime) / animationTime;
    var easing = progress * (2 - progress);

    if (progress >= 1) {
        currentGradient = gradientTo;
    } else {
        for (var i = 0; i < 5; i++) {
            for (var j = 0; j < 3; j++) {
                const startColor = gradientFrom[i][j];
                const newColor = gradientTo[i][j];

                const colorDiff = newColor - startColor;
                currentGradient[i][j] = startColor + colorDiff * easing;
            }
        }
    }

    var threshholds = [0, 10, 40, 60, 100];
    var style = threshholds.map((threshhold, idx) => {
        var values = currentGradient[idx].map(n => Math.round(n));
        return 'rgb(' + values.join(',') + ') ' + threshhold + '%';
    });

    const gradientString = 'linear-gradient(120deg,' + style.join(',') + ')';

    if (previousGradientString !== gradientString) {
        document
            .getElementsByClassName(gradientClass)[0]
            .style
            .backgroundImage = gradientString;
    }

    previousGradientString = gradientString;

    if (progress >= 1) {
        stopGradientAnimation();
        return;
    }

    gradientAnimation = window.requestAnimationFrame(updateGradient);
}
